<template>
  <div class="bigDiv">
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/08/02/17225777499650561.jpg"
      alt=""
    />
    <div class="title">
      填写下方帮你定制健康饮食方案
    </div>
    <div class="form">
      <div>
        <div class="formTit">1、{{ type1.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type1.daan"
            :key="res.value"
            @click="type1q = res.value"
            :class="{ formItemActive: res.value === type1q }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="form">
      <div>
        <div class="formTit">2、{{ type2.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type2.daan"
            :key="res.value"
            @click="type2q = res.value"
            :class="{ formItemActive: res.value === type2q }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="form">
      <div>
        <div class="formTit">3、{{ type3.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type3.daan"
            :key="res.value"
            @click="type3q = res.value"
            :class="{ formItemActive: res.value === type3q }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="form">
      <div>
        <div class="formTit">4、{{ type4.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type4.daan"
            :key="res.value"
            @click="type4q = res.value"
            :class="{ formItemActive: res.value === type4q }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="button" @click="throttleSubmit">一元拼团领直播课</div>
    <div
      class="div"
      style="
      display: flex;"
    >
      <van-checkbox v-model="checked" class="checked"></van-checkbox
      ><span class="span"
        >报名即视为同意<a
          href="https://oss.hshwhkj.com/images/%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E6%8E%88%E6%9D%83%E4%B8%8E%E4%BF%9D%E6%8A%A4%E5%A3%B0%E6%98%8E.html"
          >《个人信息保护声明》</a
        ></span
      >
    </div>
    <!-- <div class="divs">
      <van-field v-model="query.mobile" center clearable placeholder="请输入手机号" :formatter="formatter" style="margin-top: 10px;margin-bottom: 15px;" :maxlength="11">

      </van-field>
      <van-field v-model="code" center clearable placeholder="请输入短信验证码">
        <template #right-icon>
          <div size="small" type="primary" @click="getVerify" :class="{blue:!flag}" class="vbtn">{{flag? '获取验证码' : `${time}秒后可重新发送`}}</div>
        </template>
      </van-field>
      <div class="btn" @click="throttleSubmit">抢特价名额</div>
    </div> -->
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/08/02/172257777672277631.jpg"
      alt=""
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/08/02/17225836178904928.jpg"
      alt=""
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/08/02/172258366626702482.jpg"
      alt=""
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/08/02/172257783111389874.jpg"
      alt=""
      class="fixd"
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/07/29/172223038065950649.gif"
      alt=""
      class="btofixd"
      @click="throttleSubmit"
    />
  </div>
</template>

<script>
// import { getWxCsad } from "@/api/360AD.js";
import { getVerify, getTgUnionid, getTgUnifyPayH5 } from "../../api/components";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      checked: false,

      type1: {
        name: "您的工作性质",
        daan: [
          { name: "白班", value: "1" },
          { name: "夜班", value: "2" },
          { name: "退休", value: "3" },
          { name: "其他", value: "4" },
        ],
      },
      type2: {
        name: "您是否有健康管理、调理经验",
        daan: [
          { name: "没有", value: "5" },
          { name: "自己调理", value: "6" },
          { name: "定期体检", value: "7" },
          { name: "专业营养师调理", value: "8" },
        ],
      },
      type3: {
        name: "您在健康调理已经花费了多少?",
        daan: [
          { name: "0元", value: "9" },
          { name: "100-5000", value: "10" },
          { name: "5000以上", value: "11" },
        ],
      },
      type4: {
        name: "您希望元满帮您什么",
        daan: [
          { name: "调理问题", value: "12" },
          { name: "预防问题", value: "13" },
          { name: "家庭饮食搭配", value: "14" },
          { name: "身材管理", value: "15" },
        ],
      },
      type1q: "",
      type2q: "",
      type3q: "",
      type4q: "",
      query: {
        mobile: "",
      },
      memberInfo: {
        open_id: "",
      },
      code: "",
      time: 60,
      flag: true,
      ruleForm: {
        land_link: "", //落地页链接
      },
    };
  },
  created() {
    const code = this.$route.query.code;
    this.query.mobile = this.$route.query.mobile;
    this.code = this.$route.query.mobilecode;
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("mobile");
    currentUrl.searchParams.delete("mobilecode");
    currentUrl.searchParams.delete("code");
    currentUrl.searchParams.delete("state");
    this.ruleForm.land_link = currentUrl.href;
    if (code) {
      this.getTgUnionid();
    }
  },
  methods: {
    formatter(value) {
      // 仅保留输入的数字
      return value.replace(/\D/g, "");
    },
    async getVerify() {
      const res = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (res.test(this.query.mobile)) {
        if (this.flag) {
          this.flag = false;
          const data = await getVerify(this.query);
          this.startCountdown();
          console.log(data);
        }
      } else {
        Toast.fail("请输入正确的手机号!");
      }
    },
    startCountdown() {
      if (this.timer) {
        clearInterval(this.timer); // 清除之前的定时器
      }
      this.flag = false; // 重置结束标志
      this.time = 60; // 重置时间
      this.timer = setInterval(() => {
        if (this.time <= 0) {
          clearInterval(this.timer); // 清除定时器
          this.flag = true; // 设置结束标志
          this.time = 60; // 重新设置时间
          return;
        }
        this.time -= 1; // 减少时间
      }, 1000);
    },
    timeore() {
      const times = setInterval(() => {
        if (this.time === 0) {
          this.flag = true;
          this.time = 60;

          return;
        }
        this.time = this.time - 1;
        this.timeore();
      }, 1000);
    },
    throttleSubmit() {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      this.wxClick();
    },
    async wxClick() {
      // const res = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
      // if (res.test(this.query.mobile) && this.code !== '') {
      //   const qyeru = {
      //     mobile: this.query.mobile,
      //     mobile_code: this.code,
      //     land_link: this.ruleForm.land_link
      //   }
      if (
        this.type1q === "" ||
        this.type2q === "" ||
        this.type3q === "" ||
        this.type4q === ""
      ) {
        window.scrollTo(0, 150);
        return Toast.fail("您还没有做完题目~");
      }
      if (!this.checked) {
        window.scrollTo(0, 550);

        return Toast.fail("请先同意隐私协议");
      }
      if (this.is_weixn()) {
        this.getCode();
      } else {
        this.getTgUnifyPayH5();
      }

      // } else {
      //   window.scrollTo(0, 0)
      //   Toast.fail('请输入正确的手机号/验证码!')
      // }
    },

    is_weixn() {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)) {
        return true;
      } else {
        return false;
      }
    },
    async getCode() {
      console.log(123, "获取code");

      let scope = "snsapi_userinfo"; //手動授權
      let info = {
        appid: "wx143b46f871cc09d6",
      };

      const parsedUrl = new URL(window.location.href);
      const basAAA = parsedUrl.origin;
      const redirectUrl = basAAA;
      const { code, mobile, mobilecode, ...rest } = this.$route.query;

      // 将剩余字段转换为查询字符串
      const queryString = new URLSearchParams(rest).toString();

      console.log(123, queryString);
      window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          info.appid
        }&redirect_uri=${encodeURIComponent(
          `${redirectUrl}/txApay1Nomobile?${queryString}`
        )}&response_type=code&scope=${scope}#wechat_redirect`
      );
      // }
    },
    async getTgUnionid() {
      try {
        const query = {
          code: this.$route.query.code,
          // mobile: this.query.mobile,
          // mobile_code: this.code,
          land_link: this.ruleForm.land_link,
          appid: "wx143b46f871cc09d6",
        };
        const { data } = await getTgUnionid(query);
        this.memberInfo = data.data;
        console.log(data.data);
        console.log(this.memberInfo, "88");
        this.$toast.loading("正在加载,请稍等~");
        this.getTgUnifyPayH5();
      } catch (error) {
        console.log(error);
      }
    },
    async getTgUnifyPayH5() {
      try {
        const query = {
          appid: "wx143b46f871cc09d6",
          open_id: this.memberInfo.openid,
          price: this.$route.query.price || 0,
          unionid: this.memberInfo.unionid,
          headimgurl: this.memberInfo.headimgurl,
          wechat_name: this.memberInfo.nickname,
          // mobile: this.query.mobile,
          // mobile_code: this.code,
          land_link: this.ruleForm.land_link,
        };
        const res = await getTgUnifyPayH5(query);
        console.log(res, "8897879");
        this.wechatPay(res);
      } catch (error) {
        console.log(error, "我是错误");
        // this.getCode()
      }
    },

    wechatPay(res) {
      if (this.is_weixn()) {
        const {
          appId,
          timestamp,
          nonceStr,
          signature,
          paySign,
          signType,
          trade_no,
        } = res.data.data.data;

        wx.config({
          debug: false,
          appId: "wx143b46f871cc09d6",
          timestamp,
          nonceStr,
          signature,
          jsApiList: ["chooseWXPay"],
        });
        this.$toast.clear();

        wx.chooseWXPay({
          timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          nonceStr, // 支付签名随机串，不长于 32 位
          package: res.data.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign, // 支付签名
          success: (res) => {
            console.log("成功");
            // this.isPay = true
            // this.ISshow = true
            // window.location.href = 'https://work.weixin.qq.com/ca/cawcdee1a6bfffe85c?customer_channel=zmwk_BRYtC_c_{}_p_{}'

            // 支付成功后的回调函数
            this.$router.replace({
              path: "/txTgPayOk",
              query: {
                trade_no: trade_no,
              },
            });
          },
        });
      } else {
        console.log(res.data.data.data);
        window.location.href = res.data.data.data;
      }
    },
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
* {
  font-family: "Microsoft YaHei";
}
.bigDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  .title {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: #24715e;
    margin: 10px auto;
    margin-top: 20px;
  }
  .div {
    width: 310px;
    margin: 0 auto;
    margin-bottom: 15px;
    .span {
      color: #666;

      a {
        color: #666;
        border-bottom: 1px solid #666;
      }
    }
    .checked {
      margin: 0 auto;
      font-size: 16px;
      color: #666;
    }
    font-size: 16px;
  }

  .form {
    width: 100%;
    padding: 0 30px;
    .formTit {
      font-size: 16px;
    }
    .wenti {
      display: flex;
      flex-wrap: wrap;
      .formItem {
        width: 130px;
        height: 40px;
        text-align: center;
        font-size: 15px;
        line-height: 40px;
        background-color: #f8f8f8;
        margin: 10px;
        border-radius: 10px;
      }
      .formItemActive {
        background-color: #0ec9b0;
        color: #fff;
      }
    }
  }
  .button {
    width: 320px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #e71515;
    color: #fff;
    margin: 10px auto;
    border-radius: 50px;
    animation: pulse 0.8s infinite;
    cursor: pointer;
  }
  .van-radio {
    margin: 10px 0;
  }
  .blue {
    background-color: #ccc !important;
    border: none;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
  .fixd {
    margin-bottom: 120px;
  }
  .divs {
    background-color: #b5f1f9;
    margin: 20px 0;
  }
  .btofixd {
    width: 375px;
    position: fixed;
    bottom: 0;
  }
  .vbtn {
    width: 120px;
    height: 45px;
    border-radius: 8px;
    border: none;
    background-color: #0ec9b0;
    color: #fff;
    text-align: center;
    line-height: 45px;
    position: relative;
    right: -16px;
    font-size: 16px;
    font-weight: 700;
  }
  .btn {
    width: 320px;
    height: 40px;
    border-radius: 20px;
    margin: 10px auto;
    text-align: center;
    line-height: 40px;
    background-color: #f76f17;
    color: #fff;
    font-size: 24px;
    animation: pulse 1s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
      object-fit: contain;
    }
    .div {
      width: 310px;
      margin: 0 auto;
      margin-bottom: 15px;
      .span {
        color: #666;

        a {
          color: #666;
          border-bottom: 1px solid #666;
        }
      }
      .checked {
        margin: 0 auto;
        font-size: 16px;
        color: #666;
      }
      font-size: 16px;
    }
    .button {
      width: 720px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #e71515;
      color: #fff;
      margin: 10px auto;
      border-radius: 50px;
      cursor: pointer;

      animation: pulse 0.8s infinite;
    }
    .title {
      text-align: center;
      font-size: 22px;
      font-weight: 700;
      color: #24715e;
      margin: 10px auto;
      margin-top: 20px;
    }
    .form {
      width: 100%;
      padding: 0 30px;
      .formTit {
        font-size: 16px;
      }
      .wenti {
        display: flex;
        flex-wrap: wrap;
        .formItem {
          width: 130px;
          height: 40px;
          text-align: center;
          font-size: 15px;
          line-height: 40px;
          background-color: #f8f8f8;
          margin: 10px;
          border-radius: 10px;
          cursor: pointer;
        }
        .formItemActive {
          background-color: #0ec9b0;
          color: #fff;
        }
      }
    }

    .fixd {
      margin-bottom: 50px;
    }
    .btofixd {
      width: 750px;
      position: fixed;
      bottom: 0;
    }
    .vbtn {
      height: 40px;
      border-radius: 8px;
    }
    .van-field {
      width: 750px;
      margin: 0 auto;
      margin-bottom: 5px;
      border-radius: 12px;
      height: 50px;
    }
    .van-cell {
      background-color: #e8ebe8;
    }
    .btn {
      width: 750px;
      height: 40px;
      border-radius: 20px;
      margin: 10px auto;
      text-align: center;
      line-height: 40px;
      background-color: #ff0000;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      animation: pulse 1s infinite;
    }
  }
}
.van-field {
  width: 320px;
  margin: 0 auto;
  margin-bottom: 5px;
  border-radius: 12px;
  height: 45px;
}
</style>
<style>
.van-field__control {
  font-size: 16px !important;
}
</style>
